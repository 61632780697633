import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  GenderOptions,
  CountryCodes,
  InsuranceFormFields,
  DateTimeFormats,
  SELF_SUBSCRIBER_RELATION_CODE,
  GroupNameMaxLength,
  GroupNumMaxLength,
  ValidationTypes,
  SubscriberIdTypes,
  GroupNoDefaultValue
} from "../../constants";
import {
  getStates_Ajax,
  getInsuranceCarriers_Ajax,
  getInsuranceProductTypes_Ajax,
  getPatientProfileSettings_Ajax
} from "../../helpers/requests";
import ReactTooltip from "react-tooltip";
import {
  formatDateTime,
  handleApiErrors,
  isEmptyArray,
  isValidDate,
  showAlertDialouge,
  validateGroupNo,
  validateSubscriberID,
  getAddressFromGooglePlaceObject
} from "../../helpers/utils";
import Select from "react-select";
import InsuranceRelationOptions from "../RelationOptions/InsuranceRelationOptions";
import {
  showLoadingSpinner,
  hideLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import DateInputMask from "../DateInput/DateInputMask";
import DentalInsuranceInfo from "./DentalInsuranceInfo";
import useShowVerbiageBasedOnNonParCarrierSelected from "../CustomHooks/useShowVerbiageBasedOnNonParCarrierSelected";
import Autocomplete from "../GooglePlaceAutoComplete/AutoComplete";
import SubscriberCardModal from "./SubscriberCardModal";
import { getPatientOrigin, getPgId } from "../../ApplicationSettings";

const InsuranceForm = (props) => {
  const {
    insuranceFormData,
    setDeafultCarrierData = () => {},
    setHasCarrierLoaded = () => {},
    showApplyToDependentsOption,
    shouldShowProductTypeField,
    signUpFormData,
    isInSignUpPageFlow,
    handleBackButton,
    validRelations = [],
    type,
    setIsInsuranceFormVisible,
    isInOnDemandFlow = false,
    shouldNavigateToNextPage,
    saveButtonVerbiage,
    setSelectedCarrierId = () => {},
    handleSkipRegistrationInsuranceCapture,
    selectedCarrierId = 0,
    isSecondOpinionFlow,
    isAddDependentFlow,
    focusFieldName
  } = props;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    formState: { isValid, errors }
  } = useForm({ mode: "onChange" });

  const shouldShowSubscriberIdHint = useSelector(
    (state) => state?.appSettings?.settingsData?.shouldShowSubscriberIDHint
  );

  const shouldShowGroupNoHint = useSelector(
    (state) => state?.appSettings?.settingsData?.shouldShowGroupNoHint
  );

  const dispatch = useDispatch();
  const genderWatch = watch(InsuranceFormFields.Gender);
  let prevRelationToSubscriber = useRef(InsuranceFormFields.PatientRelation);
  function populateCarrierInfo(data) {
    if (data) {
      if (shouldShowProductTypeField) {
        let carrierInfo = getCarrierInfoFromProductType(
          getValues(InsuranceFormFields.ProductType)
        );

        if (carrierInfo) {
          data[InsuranceFormFields.InsuranceCarrier] = carrierInfo.carrierCode;
          data[InsuranceFormFields.InsuranceCarrierName] =
            carrierInfo.carrierName;
          data[InsuranceFormFields.GroupName] =
            carrierInfo.carrierName.substring(0, GroupNameMaxLength);
          data[InsuranceFormFields.GroupNo] = carrierInfo.carrierName.substring(
            0,
            GroupNumMaxLength
          );
        }
      } else if (
        !isEmptyArray(insuranceCarrierOptions) &&
        data[InsuranceFormFields.InsuranceCarrierName]
      ) {
        let carrierInfo = insuranceCarrierOptions.find(
          (c) => c.carrierName == data[InsuranceFormFields.InsuranceCarrierName]
        );

        if (carrierInfo) {
          data[InsuranceFormFields.InsuranceCarrier] = carrierInfo.carrierCode;
        }
      }
    }
  }

  function populateGroupName(data) {
    if (
      data &&
      !data[InsuranceFormFields.GroupName] &&
      data[InsuranceFormFields.InsuranceCarrierName]
    ) {
      data[InsuranceFormFields.GroupName] = data[
        InsuranceFormFields.InsuranceCarrierName
      ].substring(0, GroupNameMaxLength);
    }
  }

  const clickSave = (data) => {
    setApplyToAllDependentsChanged(false);

    populateCarrierInfo(data);
    populateGroupName(data);
    data[InsuranceFormFields.SubscriberIdType] = subscriberIdType;
    if (subscriberIdType == SubscriberIdTypes.SSN) {
      data[InsuranceFormFields.SSN] = data[InsuranceFormFields.SubscriberID];
    }

    props.onSubmit(data);
    if (isInOnDemandFlow) {
      setIsInsuranceFormVisible(false);
      shouldNavigateToNextPage();
    }
  };

  function handleIsApplicableToDependentClick() {
    setValue(
      InsuranceFormFields.ApplyToAllDependents,
      !isApplicableToDependent
    );
    setIsApplicableToDependent(!isApplicableToDependent);
  }

  const [states, setStates] = useState([]);
  const [insuranceCarrierOptions, setInsuranceCarrierOptions] = useState([]);
  const [applyToAllDependentsChanged, setApplyToAllDependentsChanged] =
    useState(false);
  const relationWatch = watch(InsuranceFormFields.PatientRelation);
  const [insuranceCarrierOptionsToSelect, setInsuranceCarrierOptionsToSelect] =
    useState([]);
  const [isCarrierUpdated, setIsCarrierUpdated] = useState(false);
  const [insuraceProductTypes, setInsuraceProductTypes] = useState([]);
  const [insuraceProductTypesToSelect, setInsuraceProductTypesToSelect] =
    useState([]);
  const patientOrigin = getPatientOrigin();
  const currentPatient = useSelector((state) => state.userData.currentPatient);
  const [isApplicableToDependent, setIsApplicableToDependent] = useState();
  const [subscriberIdType, setSubscriberIdType] = useState(0);
  const [isCarrierSelectionDisabled, setIsCarrierSelectionDisabled] =
    useState(false);
  const showVerbiageBasedOnNonParCarrierSelected =
    useShowVerbiageBasedOnNonParCarrierSelected(
      currentPatient?.patid,
      selectedCarrierId
    );
  const parCarrierCheckedEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.ParCarrierCheckedEnabled
  );
  const shouldShowDefaultGroupNoForInsurance = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.shouldShowDefaultGroupNoForInsurance
  );
  const areAllInsuranceFormFieldRequired = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.areAllInsuranceFormFieldRequired
  );
  const shouldSetSubscriberTypeFromQueryParams = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.shouldSetSubscriberTypeFromQueryParams
  );
  const isGroupNoFieldEnabledForInsurance = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.shouldShowGroupNoFieldForInsurance
  );

  useEffect(() => {
    if (shouldSetSubscriberTypeFromQueryParams && type > 0) {
      setSubscriberIdType(type);
    }
  }, [type]);

  const insuranceSubscriberIDValidator = useSelector(
    (state) => state?.appSettings?.settingsData?.insuranceSubscriberIDValidator
  );

  const patientRelationRef = useRef(null);

  useEffect(() => {
    let relationCode = getValues(InsuranceFormFields.PatientRelation);

    if (relationCode == SELF_SUBSCRIBER_RELATION_CODE && signUpFormData) {
      populateFormDataWithSelf(signUpFormData);
    } else if (
      relationCode == SELF_SUBSCRIBER_RELATION_CODE &&
      prevRelationToSubscriber.current != SELF_SUBSCRIBER_RELATION_CODE
    ) {
      fetchCurrentPatientAndPopulateForm(currentPatient?.patid);
    }
    prevRelationToSubscriber.current = relationCode;
  }, [relationWatch]);

  function fetchCurrentPatientAndPopulateForm(patid) {
    dispatch(showLoadingSpinner());
    getPatientProfileSettings_Ajax(patid, function (response) {
      dispatch(hideLoadingSpinner());
      if (response?.success && response?.data) {
        populateFormDataWithSelf(response.data);
      }
    });
  }

  function populateFormDataWithSelf(patientInfo) {
    setValue(InsuranceFormFields.FirstName, patientInfo?.firstName, {
      shouldValidate: true
    });
    setValue(InsuranceFormFields.LastName, patientInfo?.lastName, {
      shouldValidate: true
    });
    setValue(
      InsuranceFormFields.DateOfBirth,
      formatDateTime(patientInfo?.dateOfBirth, DateTimeFormats.MM_DD_YYYY),
      { shouldValidate: true }
    );
    if (patientInfo?.gender) {
      setValue(InsuranceFormFields.Gender, patientInfo?.gender, {
        shouldValidate: true
      });
    }
    if (patientInfo?.state) {
      setValue(InsuranceFormFields.State, patientInfo?.state, {
        shouldValidate: true
      });
    }
    if (patientInfo?.city) {
      setValue(InsuranceFormFields.City, patientInfo?.city, {
        shouldValidate: true
      });
    }
    if (patientInfo?.zip) {
      setValue(InsuranceFormFields.Zip, patientInfo?.zip, {
        shouldValidate: true
      });
    }
    if (patientInfo?.address1) {
      setValue(InsuranceFormFields.Address, patientInfo?.address1, {
        shouldValidate: true
      });
    }
  }
  useEffect(() => {
    getStates(CountryCodes.US);
  }, []);

  useEffect(() => {
    setInputFieldFocus(focusFieldName);
  }, [focusFieldName]);

  useEffect(() => {
    populateForm(insuranceFormData);
    populateCarrierId();
  }, [insuranceFormData]);

  useEffect(() => {
    getInsuranceCarrierOptions();
  }, []);

  useEffect(() => {
    if (shouldShowProductTypeField) {
      getInsuranceProductTypes();
    }
  }, [shouldShowProductTypeField]);

  useEffect(() => {
    if (!isEmptyArray(states)) {
      setInsuranceState();
    }
  }, [states]);

  useEffect(() => {
    if (insuranceCarrierOptions.length == 1) {
      setValue(
        InsuranceFormFields.InsuranceCarrierName,
        insuranceCarrierOptions[0].carrierName
      );
      setValue(
        InsuranceFormFields.InsuranceCarrier,
        insuranceCarrierOptions[0].carrierCode
      );

      if (setDeafultCarrierData) {
        setDeafultCarrierData({
          carrierCode: insuranceCarrierOptions[0].carrierCode,
          carrierName: insuranceCarrierOptions[0].carrierName
        });
      }

      setIsCarrierSelectionDisabled(true);
    }
  }, [insuranceCarrierOptions]);
  function setInsuranceState() {
    if (signUpFormData?.state) {
      setValue(InsuranceFormFields.State, insuranceFormData?.state ? insuranceFormData.state : signUpFormData.state);
    }
  }

  function getCarrierInfoFromProductType(productTypeId) {
    if (productTypeId) {
      let product = insuraceProductTypes.find((product) => {
        return product.productTypeId == productTypeId;
      });

      if (product) {
        return {
          carrierCode: product.carrierCode,
          carrierName: product.carrierName
        };
      }
    }
  }

  function setInputFieldFocus(fieldName) {
    if (
      fieldName == InsuranceFormFields.PatientRelation &&
      patientRelationRef.current
    ) {
      patientRelationRef.current?.focus();
    }
  }

  function getInsuranceProductTypes() {
    if (patientOrigin) {
      let params = {
        client: patientOrigin
      };
      getInsuranceProductTypes_Ajax(getPgId(), params, function (response) {
        if (response?.success && response?.data) {
          let productTypes = [];
          response.data.forEach((product) => {
            productTypes.push({
              value: product.productTypeId,
              label: product.name
            });
          });
          setInsuraceProductTypesToSelect(productTypes);
          setInsuraceProductTypes(response.data);
        } else if (!response?.success && response?.message) {
          showAlertDialouge("Error", response.message);
        }
      });
    }
  }

  function getInsuranceCarrierOptions() {
    let params = {
      clientId: patientOrigin
    };

    getInsuranceCarriers_Ajax(params, function (response) {
      if (response && response.success && response.data) {
        let carriers = [];

        response.data.forEach((option) => {
          carriers.push({
            value: option.carrierName,
            label: option.carrierName,
            id: option.id,
            code: option.carrierCode,
            contractedType: option.contracted
          });
        });
        carriers = sortInsuranceCarriers(carriers);
        setInsuranceCarrierOptions(response.data);
        setInsuranceCarrierOptionsToSelect(carriers);

        if (setHasCarrierLoaded) {
          setHasCarrierLoaded(true);
        }
      }
    });
  }

  function sortInsuranceCarriers(carriers) {
    if (parCarrierCheckedEnabled && !isEmptyArray(carriers)) {
      return [...carriers].sort((a, b) => b.contractedType - a.contractedType);
    }
    return carriers;
  }

  function acceptNumbersOnly(event) {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

  function getStates(countryCode) {
    return new Promise(function (resolve) {
      if (countryCode) {
        getStates_Ajax(
          countryCode,
          function (response) {
            if (response && response.success && response.data) {
              let stateOptions = [];
              response.data.forEach((state) => {
                stateOptions.push({
                  value: state.value,
                  label: state.name
                });
              });
              setStates(stateOptions);
              resolve(true);
            }
          },
          function (err) {
            handleApiErrors(err);
            resolve(false);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  const handleSubscriberAddress = (place) => {

    if (place?.name) {
      setValue(InsuranceFormFields.Address, place.name, {
        shouldValidate: true
      });
    }

    let address = getAddressFromGooglePlaceObject(place);

    if (address == null) {
      return;
    }
    
    setValue(InsuranceFormFields.State, address.state, {
        shouldValidate: true
    });
    
    setValue(InsuranceFormFields.City, address.city, {
        shouldValidate: true
    });
    
    setValue(InsuranceFormFields.Zip, address.zip, {
        shouldValidate: true
      });
  };

  const populateForm = (insuranceData) => {
    if (insuranceData) {
      setValue(InsuranceFormFields.State, insuranceData.state, {
        shouldValidate: true
      });
      setValue(InsuranceFormFields.City, insuranceData.insuredCity, {
        shouldValidate: true
      });
      setValue(InsuranceFormFields.Zip, insuranceData.zip, {
        shouldValidate: true
      });
      setValue(InsuranceFormFields.FirstName, insuranceData.insuredFirstName, {
        shouldValidate: true
      });
      setValue(InsuranceFormFields.LastName, insuranceData.insuredLastName, {
        shouldValidate: true
      });

      if (isValidDate(insuranceData.insuredDob)) {
        setValue(
          InsuranceFormFields.DateOfBirth,
          formatDateTime(insuranceData.insuredDob, DateTimeFormats.MM_DD_YYYY),
          {
            shouldValidate: true
          }
        );
      } else {
        setValue(InsuranceFormFields.DateOfBirth, "");
      }

      setValue(InsuranceFormFields.Address, insuranceData.insuredAddress, {
        shouldValidate: true
      });
      setValue(InsuranceFormFields.Employer, insuranceData.employerName, {
        shouldValidate: true
      });
      setValue(InsuranceFormFields.Gender, insuranceData.gender, {
        shouldValidate: true
      });
      setValue(
        InsuranceFormFields.InsuranceCarrierName,
        insuranceData.carrierName,
        {
          shouldValidate: true
        }
      );
      setValue(
        InsuranceFormFields.InsuranceCarrier,
        insuranceData.carrierCode,
        {
          shouldValidate: true
        }
      );
      setValue(
        InsuranceFormFields.PatientRelation,
        insuranceData.relationToPatient,
        {
          shouldValidate: true
        }
      );
      prevRelationToSubscriber.current = insuranceData.relationToPatient;
      setValue(InsuranceFormFields.SubscriberID, insuranceData.insuredId, {
        shouldValidate: true
      });

      setValue(
        InsuranceFormFields.ApplyToAllDependents,
        insuranceData.isApplicableToDependent,
        {
          shouldValidate: true
        }
      );
      setIsApplicableToDependent(insuranceData.isApplicableToDependent);

      setValue(InsuranceFormFields.Country, CountryCodes.US);

      setValue(InsuranceFormFields.GroupNo, getGroupNo(insuranceData.groupNo), {
        shouldValidate: true
      });

      setValue(InsuranceFormFields.ProductType, insuranceData.productTypeId, {
        shouldValidate: true
      });
      if (insuranceData.subscriberIdType) {
        setSubscriberIdType(insuranceData.subscriberIdType);
      }
      setValue(InsuranceFormFields.SSN, insuranceData.ssn);
    }
  };

  function isSaveBtnVisible() {
    return isValid || applyToAllDependentsChanged || isCarrierUpdated;
  }

  function handleApplyToAllDependentsChange() {
    setApplyToAllDependentsChanged(true);
  }

  function hasPermissionToSkip() {
    return isInSignUpPageFlow && parCarrierCheckedEnabled;
  }

  function showInsuranceCarrier() {
    return (
      <div className="form-group mb-3 col-md-6">
        {shouldShowProductTypeField ? (
          <>
            <label className="form-label" htmlFor="subscriber-product-type">
              Product Type<span className="text-danger">*</span>
            </label>
            <Controller
              control={control}
              name={InsuranceFormFields.ProductType}
              render={({ field }) => (
                <Select
                  classNamePrefix="react-select"
                  placeholder="Select product type"
                  options={insuraceProductTypesToSelect}
                  onChange={(product) => {
                    field.onChange(product.value);
                  }}
                  value={insuraceProductTypesToSelect.find((product) => {
                    return product.value == field.value;
                  })}
                  menuPlacement="auto"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#3fbbeb",
                      primary: "#3fbbeb"
                    }
                  })}
                />
              )}
              rules={{
                required: "This field is required."
              }}
            />
          </>
        ) : (
          <>
            <label className="form-label" htmlFor="subscriber-carrier">
              Dental Insurance Carrier <span className="text-danger">*</span>
            </label>
            <Controller
              control={control}
              name={InsuranceFormFields.InsuranceCarrierName}
              defaultValue={InsuranceFormFields.InsuranceCarrierName}
              render={({ field }) => (
                <Select
                  classNamePrefix="react-select"
                  isDisabled={isCarrierSelectionDisabled}
                  placeholder="Select Insurance Carrier"
                  options={insuranceCarrierOptionsToSelect}
                  onChange={(carrier) => {
                    field.onChange(carrier.value);
                    setSelectedCarrierId(carrier.id);
                  }}
                  value={insuranceCarrierOptionsToSelect.find(
                    (option) => option.value === field.value
                  )}
                  components={
                    isCarrierSelectionDisabled
                      ? {
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null
                        }
                      : {}
                  }
                  menuPlacement="auto"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#3fbbeb",
                      primary: "#3fbbeb"
                    }
                  })}
                />
              )}
              rules={{
                required: "This field is required."
              }}
            />
          </>
        )}
      </div>
    );
  }

  function getTheVerbiageForSaveButton() {
    if (isInOnDemandFlow || isSecondOpinionFlow) {
      return saveButtonVerbiage;
    }

    return "Save";
  }

  function shouldDisplayInsuranceFormTitle() {
    return !isInOnDemandFlow;
  }

  function shouldDisplayFullInsuranceForm() {
    if (isInSignUpPageFlow && parCarrierCheckedEnabled) {
      return getValues(InsuranceFormFields.InsuranceCarrierName);
    }
    return true;
  }

  function populateCarrierId() {
    const carrierName = getValues(InsuranceFormFields.InsuranceCarrierName);
    const carrierCode = getValues(InsuranceFormFields.InsuranceCarrier);
    if (carrierCode && carrierName) {
      let selectedCarrierInfo = insuranceCarrierOptionsToSelect?.find(
        (item) => item.value === carrierName && item.code === carrierCode
      );
      setSelectedCarrierId(selectedCarrierInfo?.id);
    }
  }

  function displayNonParCarrierVerbiage() {
    if (isInSignUpPageFlow) {
      return <>{showVerbiageBasedOnNonParCarrierSelected()}</>;
    }
    return <></>;
  }

  function shouldRequireAllFields() {
    return areAllInsuranceFormFieldRequired;
  }

  function getGroupNo(insurancDataGroupNo) {
    if (insurancDataGroupNo != null && insurancDataGroupNo.length > 0) {
      return insurancDataGroupNo;
    }
    if (shouldShowDefaultGroupNoForInsurance) {
      return GroupNoDefaultValue;
    }
    return insurancDataGroupNo;
  }

  return (
    <>
      <form
        className="bg-white border-radius-xlg px-4 px-md-5 py-4"
        onSubmit={handleSubmit(clickSave)}
      >
        {shouldDisplayInsuranceFormTitle() && (
          <>
            <h2 className="text-center text-violet mb-4">
              Dental Insurance Information
            </h2>
            {shouldRequireAllFields() && (
              <div className="text-secondary fw-bold fs-5 mb-1">
                * All fields are required
              </div>
            )}
            {showApplyToDependentsOption ? (
              <div className="form-group mb-3 col-md-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="availProvider"
                    id="availProvider"
                    {...register(InsuranceFormFields.ApplyToAllDependents)}
                    onClick={handleIsApplicableToDependentClick}
                    onChange={handleApplyToAllDependentsChange}
                  />
                  <label className="form-check-label" for="availProvider">
                    Apply the same insurance to all dependents
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )}
            {!shouldDisplayFullInsuranceForm() && <DentalInsuranceInfo />}
            {displayNonParCarrierVerbiage()}
          </>
        )}
        {showInsuranceCarrier()}
        {shouldDisplayFullInsuranceForm() && (
          <>
            <div>
              <div className="row">
                <div className="form-group mb-3 col-lg-6">
                  <label className="form-label" htmlFor="relation">
                    Patient Relation to insurance Subscriber{" "}
                    <span className="text-danger">*</span>
                    <>
                      <i
                        className="bi bi-info-circle-fill fs-3 text-primary ms-2"
                        data-place="top"
                        data-for="relationTooltip"
                        data-type="light"
                        data-tip="This is the relationship to the insurance subscriber."
                      ></i>
                      <ReactTooltip id="relationTooltip" className="tooltip" />
                    </>
                  </label>
                  <InsuranceRelationOptions
                    ref={patientRelationRef}
                    insuranceRelation={InsuranceFormFields.PatientRelation}
                    control={control}
                    validRelations={validRelations}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label className="form-label" htmlFor="subscriber-fname">
                  Subscriber First Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="subscriber-fname"
                  placeholder="Subscriber First Name"
                  {...register(InsuranceFormFields.FirstName, {
                    required: true
                  })}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="form-label" htmlFor="subscriber-lname">
                  Subscriber Last Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="subscriber-lname"
                  placeholder="Subscriber Last Name"
                  {...register(InsuranceFormFields.LastName, {
                    required: true
                  })}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="form-label w-100">
                  Subscriber Gender <span className="text-danger">*</span>
                </label>
                <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="anyAllergy"
                    id="genderMale"
                    value={GenderOptions.Male}
                    {...register(InsuranceFormFields.Gender, {
                      required: true
                    })}
                  />
                  <label className="form-check-label fs-4" htmlFor="genderMale">
                    Male
                  </label>
                </div>
                <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="anyAllergy"
                    id="genderFemale"
                    value={GenderOptions.Female}
                    {...register(InsuranceFormFields.Gender, {
                      required: true
                    })}
                  />
                  <label
                    className="form-check-label fs-4"
                    htmlFor="genderFemale"
                  >
                    Female
                  </label>
                </div>
                <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="anyAllergy"
                    id="genderOther"
                    value={GenderOptions.Other}
                    checked={
                      genderWatch == GenderOptions.Unknown ||
                      genderWatch == GenderOptions.Other
                    }
                    {...register(InsuranceFormFields.Gender, {
                      required: true
                    })}
                  />
                  <label
                    className="form-check-label fs-4"
                    htmlFor="genderOther"
                  >
                    Other
                  </label>
                </div>
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="form-label" htmlFor="number-input-date">
                  Subscriber Date of Birth{" "}
                  <span className="text-danger">*</span>
                </label>
                <DateInputMask
                  register={register}
                  fieldName={InsuranceFormFields.DateOfBirth}
                  errors={errors}
                  validationType={ValidationTypes.DateOfBirth}
                />
              </div>
            </div>
            <div className="row mt-4">
              {subscriberIdType == SubscriberIdTypes.EEID && (
                <div className="form-group mb-3 col-md-6">
                  <label
                    className="form-label"
                    htmlFor="subscriber-employee-id"
                  >
                    Subscriber Employee ID (EEID){" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="subscriber-employee-id"
                    placeholder="Subscriber Employee ID"
                    {...register(InsuranceFormFields.SubscriberID, {
                      required: true
                    })}
                  />
                </div>
              )}
              {subscriberIdType == SubscriberIdTypes.SSN && (
                <div className="form-group mb-3 col-md-6">
                  <label className="form-label" htmlFor="subscriber-ssn">
                    Subscriber SSN <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="subscriber-ssn"
                    placeholder="Subscriber SSN"
                    {...register(InsuranceFormFields.SubscriberID, {
                      required: true
                    })}
                  />
                </div>
              )}
              {subscriberIdType == SubscriberIdTypes.DEFAULT && (
                <>
                  <div className="form-group mb-3 col-md-6">
                    <label className="form-label" htmlFor="subscriber-id">
                      Subscriber ID <span className="text-danger">*</span>{" "}
                      {shouldShowSubscriberIdHint ? (
                        <i
                          className="bi bi-info-circle-fill fs-3 text-primary ms-2"
                          data-bs-toggle="modal"
                          data-bs-target="#Subscriber-modal"
                        />
                      ) : (
                        <></>
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="subscriber-id"
                      placeholder="Subscriber ID"
                      {...register(InsuranceFormFields.SubscriberID, {
                        required: true,
                        validate: (value) =>
                          validateSubscriberID(
                            value,
                            insuranceSubscriberIDValidator
                          )
                      })}
                    />
                    {errors[InsuranceFormFields.SubscriberID]?.message ? (
                      <p className="help-block">
                        {errors[InsuranceFormFields.SubscriberID].message}
                      </p>
                    ) : (
                      <></>
                    )}
                    {insuranceSubscriberIDValidator?.inputHint && (
                      <div className="mt-2 small-form-check">
                        <label className="fs-12 text-muted mt-1 d-inline-block w-100">
                          {insuranceSubscriberIDValidator.inputHint}
                        </label>
                      </div>
                    )}
                  </div>

                  <div className="form-group mb-3 col-md-6">
                    <label className="form-label" htmlFor="employer">
                      Employer{" "}
                      {shouldRequireAllFields() && (
                        <span className="text-danger"> *</span>
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="employer"
                      placeholder="Employer"
                      {...register(InsuranceFormFields.Employer, {
                        required: shouldRequireAllFields()
                      })}
                    />
                  </div>
                  {isGroupNoFieldEnabledForInsurance && (
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label" htmlFor="group_no">
                        Group No <span className="text-danger">*</span>
                        {shouldShowGroupNoHint ? (
                          <i
                            className="bi bi-info-circle-fill fs-3 text-primary ms-2"
                            data-bs-toggle="modal"
                            data-bs-target="#Subscriber-modal"
                          />
                        ) : (
                          <></>
                        )}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="group_no"
                        placeholder="Group no"
                        {...register(InsuranceFormFields.GroupNo, {
                          required: true,
                          validate: (value) => validateGroupNo(value)
                        })}
                      />
                      {errors.GroupNo?.message ? (
                        <p className="help-block">{errors.GroupNo.message}</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="row mt-4">
              <div className="form-group mb-3 col-md-6">
                <label className="form-label" htmlFor="subscriber-address">
                  Subscriber Address <span className="text-danger">*</span>
                </label>
                <Autocomplete
                  handleAddress={handleSubscriberAddress}
                  existingValue={getValues(InsuranceFormFields.Address)}
                  inputElementId="subscriber-address"
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="form-label" htmlFor="city">
                  Subscriber City <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  placeholder="City"
                  {...register(InsuranceFormFields.City, { required: true })}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="form-label" htmlFor="state">
                  Subscriber State <span className="text-danger">*</span>
                </label>
                <Controller
                  control={control}
                  name={InsuranceFormFields.State}
                  defaultValue={InsuranceFormFields.State}
                  render={({ field }) => (
                    <Select
                      classNamePrefix="react-select"
                      placeholder="Select a state"
                      options={states}
                      onChange={(state) => field.onChange(state.value)}
                      value={states.find(
                        (option) => option.value === field.value
                      )}
                      menuPlacement="auto"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#3fbbeb",
                          primary: "#3fbbeb"
                        }
                      })}
                    />
                  )}
                  rules={{
                    required: "This field is required."
                  }}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="form-label" htmlFor="subscriber-zip-code">
                  Subscriber Zip<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  onKeyPress={acceptNumbersOnly}
                  id="subscriber-zip-code"
                  className="form-control"
                  placeholder="Zip Code"
                  {...register(InsuranceFormFields.Zip, {
                    required: true,
                    maxLength: {
                      value: 15,
                      message:
                        "Maximum digit limit exceeded. Please enter a number with no more than 15 digits."
                    }
                  })}
                />
                {errors.zip?.message ? (
                  <p className="help-block">{errors.zip.message}</p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        )}
        <div
          className="nav form-group text-center d-flex flex-wrap justify-content-center mt-2 mt-md-4 col-sm-12 button-wrapper"
          id="step-tab"
          role="tablist"
        >
          {(isInSignUpPageFlow || isAddDependentFlow) && (
            <div className="form-group text-center">
              <button
                className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0"
                onClick={handleBackButton}
              >
                Back
              </button>
            </div>
          )}
          <div className="form-group text-center">
            <button
              type="submit"
              className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0"
              disabled={!isSaveBtnVisible()}
            >
              {getTheVerbiageForSaveButton()}
            </button>
          </div>
        </div>
        {hasPermissionToSkip() && (
          <div className="btn-skip-alignment">
            <button
              className="btn btn-link btn-underline btn-skip"
              onClick={handleSkipRegistrationInsuranceCapture}
            >
              Skip
            </button>
          </div>
        )}
      </form>
      <SubscriberCardModal />
    </>
  );
};

export default InsuranceForm;
